@import url('//fonts.googleapis.com/css?family=Playfair+Display:700,900italic');

body {
  font: normal 300 14px/150% 'Lato', 'helvetica neue', 'Helvetica Neue Light', sans-serif; }

@each $header, $size in (h1: 24px, h2: 20px, h3: 18px, h4: 16px, h5: 15px, h6: 14px) {
  #{$header} {
    font-family: 'Playfair Display','Georgia', sans-serif;
    font-size: $size;
    font-weight: bold;
    line-height: $size*1.5; } }

h1 {
  font-weight: 900;
  font-style: italic;
  text-align: center; }
p {
  text-indent: 15px; }

.playfair {
  font-family: 'Playfair Display','Georgia', sans-serif; }

blockquote {
  position: relative;
  margin-left: 0;
  margin-bottom: 22px;
  padding-left: 40px;
  &:before {
    content: '\00ab';
    font: bold italic 40px/0.5 'Playfair Display', 'Georgia', sans-serif;
    position: absolute;
    left: 5px;
    top: 0; }
  > p {
    &:after {
      content: '\00bb';
      font: bold italic 20px/0.5 'Playfair Display', 'Georgia', sans-serif;
      margin-left: 5px; } } }

a,:link {
  color: $link;
  text-decoration: none;
  border-bottom: 1px solid transparentize($link,0.5);
  &:visited {
    color: $link_visited;
    border-color: transparentize($link_visited,0.5);
    &:hover {
      color: $link_hover;
      border-color: transparentize($link_hover,0.5); } }
  &:hover {
    text-decoration: none;
    color: $link_hover;
    border-color: transparentize($link_hover,0.5); }
  &:active {
    color: $link_active;
    border-color: transparentize($link_active,0.5); }
  &:focus {
    outline: 1px dashed transparentize($link,0.25);
    box-shadow: none; } }

ul {
  list-style: none;
  margin: 20px 0;
  padding-left: 10px;
  li {
    position: relative;
    padding-left: 15px;
    margin-bottom: 10px;
    &:before {
      content: '– ';
      position: absolute;
      left: 0;
      top: 0; } } }

hr {
  height: 1px;
  border: 0;
  background: $border_color;
  &.dashed {
    border-bottom: 1px dashed $border_color;
    background: transparent; } }

time {
  font-size: 12px;
  font-style: italic;
  color: $date; }
.text-center {
  text-align: center; }
