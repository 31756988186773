.share_links {
  margin-top: 15px;
  margin-bottom: 20px;
  &:before {
    content: 'Share, please: ';
    margin-right: 6px; }
  .share_link {
    font-style: italic;
    font-weight: 400;
    margin-right: 5px;
    &:last-child {
      margin-right: 0; } } }

#disqus_thread {
  text-align: center;
  min-height: 62px;
  margin-bottom: 20px;
  position: relative;
  .show_comments {
    font-weight: 400;
    display: inline-block;
    position: relative;
    top: 25px; } }
