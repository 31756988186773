.logo {
  @extend h1;
  text-align: center;
  color: #000;
  display: block;
  border-bottom: none;
  &:link {
    color: #000; }
  &:visited {
    color: #000;
    &:hover {
      color: #000; } }
  &:hover {
    color: #000; } }
