.post_url {
  font-weight: bold; }
.toc {
  text-align: center;
  li {
    padding: 0;
    margin: 4px 0 8px;
    >a {
      display: inline-block; }
    &:before {
      content: none; } } }
